/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-12-18",
    versionChannel: "nightly",
    buildDate: "2023-12-18T00:15:23.274Z",
    commitHash: "ecfb3b1c4c028aa3ab86835b2c2e20617698e4d9",
};
